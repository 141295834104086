import PageElement from "./wrapPageElement"
import ReactDOM from "react-dom/client";

export const wrapPageElement = PageElement

export const replaceHydrateFunction = () => {
    return (element, container) => {
        const root = ReactDOM.createRoot(container)
        root.render(element)
    }
}