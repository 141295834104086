import React from "react"
import "./styles.scss"
import love from "../../images/transheart.svg";
import {Link} from "gatsby";
import useLinks from "../../hooks/useLinks";
import { IconLogoFacebook, IconLogoPomagamPl } from "../Icons";
import ExternalLink from "../ExternalLink";

export default function Footer({alts}) {
    const links = useLinks();
    return (
        <footer className={"site-footer"}>
            <div className="footer-contents">
                <span className={"icon-links"}>
                    <ExternalLink to={links.facebook} noIcon aria-label="Facebook">
                        <IconLogoFacebook/>
                    </ExternalLink>
                    <ExternalLink to={links.pomagamPl} noIcon aria-label="Pomagam.pl">
                        <IconLogoPomagamPl/>
                    </ExternalLink>
                </span>
                {/* <p className="margin">Sfinansowano ze środków budżetowych Miasta Poznania</p> */}
                <p>© 2023 Fundacja Lambda Polska Wszelkie prawa zastrzeżone | <Link to={`/polityka-prywatnosci`}>Polityka prywatności</Link> | <ExternalLink to="https://drive.google.com/drive/folders/1Jo1pLjR5K-3Fp2ndKwG_YsiKACYFY2x0?usp=share_link">Dla prasy, do pobrania</ExternalLink></p>
                <p>e-mail: <ExternalLink noIcon to="mailto:info@dzientrans.pl">info@dzientrans.pl</ExternalLink> | tel: <ExternalLink noIcon to="tel:+48609890570">+48 609 890 570</ExternalLink></p>
                <p className={"credit"}>Made with <img src={love} alt={"love"}/> by cddlee</p>
            </div>
        </footer>
    )
}