exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mapa-js": () => import("./../../../src/pages/mapa.js" /* webpackChunkName: "component---src-pages-mapa-js" */),
  "component---src-pages-markdown-remark-page-slug-js": () => import("./../../../src/pages/{MarkdownRemarkPage.slug}.js" /* webpackChunkName: "component---src-pages-markdown-remark-page-slug-js" */),
  "component---src-pages-organizacje-js": () => import("./../../../src/pages/organizacje.js" /* webpackChunkName: "component---src-pages-organizacje-js" */),
  "component---src-pages-program-js": () => import("./../../../src/pages/program.js" /* webpackChunkName: "component---src-pages-program-js" */),
  "component---src-pages-wystapia-js": () => import("./../../../src/pages/wystapia.js" /* webpackChunkName: "component---src-pages-wystapia-js" */)
}

